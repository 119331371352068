@import './components/variables';

.home {
  &-main {
    position: relative;
    align-items: center;
    z-index: 1000;
    width: 100%;
    display: flex;

    &__info {
      position: relative;
      width: 55%;
      padding: 125px 0px;
      z-index: 9999;

      h1 {
        padding: 0;
        margin: 0;

        font-family: 'Poppins';
        color: $headings;
        font-style: normal;
        font-weight: 100;
        font-size: 120px;
        line-height: 140px;

        span {
          color: $primary;
        }
      }

      h2 {
        margin-top: 30px;
        color: rgba(255, 255, 255, 0.3);
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 300;
        font-size: 30px;
        line-height: 60px;
      }
    }
    &__animate {
      width: 100%;
      height: 100%;
      animation-name: Appearance;
      animation-duration: 3s;
      animation-timing-function: cubic-bezier(0.1, -0.6, 0.2, 0);
      &-logo {
        width: 70%;
        height: 70%;
        margin-left: 25%;
        margin-bottom: 200px;
      }
    }
  }

  &-experienced {
    width: 100%;
    position: relative;
    z-index: 1000;
    h2 {
      position: relative;
      color: $primary;
      text-transform: uppercase;
      font-family: 'Poppins';
      font-weight: 200;
      margin-bottom: 75px;
      letter-spacing: 2px;
    }

    img {
      width: 100%;
    }
  }

  &-expertise {
    position: absolute;
    width: 100%;
    height: 0px;
    left: 0px;
    img {
      left: -60px;
      top: -9vw;
      position: relative;
      width: 100%;
    }
  }

  &-approach {
    position: relative;
    z-index: 1000;
    margin-top: 120px;
    margin-bottom: 0px;
    h2 {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 300;
      font-size: 59px;
      line-height: 88px;
      text-align: center;
      color: $headings;
    }
    p {
      text-align: center;
      color: #7a7a7a;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 300;
      font-size: 26px;
      line-height: 49px;
      width: 75%;
      margin: 0 auto;
      margin-top: 50px;
      margin-bottom: 0px;
    }
    img {
      margin-top: 150px;
      width: 100%;
      margin-bottom: 100px;
    }
  }

  &-services {
    position: absolute;
    width: 100%;
    height: 300px;
    left: 0px;
    img {
      left: 0px;
      top: -60px;
      position: relative;
      width: 100%;
      z-index: 1000;
    }
  }

  &-technologies {
    display: flex;
    height: 600px;
    justify-content: space-between;
    margin-top: 300px;
    margin-bottom: 225px;
    position: relative;
    z-index: 1000;
  }

  &-works {
    position: relative;
    z-index: 1000;
    margin-bottom: 175px;
  }

  &-form {
    position: relative;
    z-index: 1000;
  }

  .bg {
    position: absolute;
    z-index: 100;
    max-width: 100%;
    top: -150px;
    left: 0px;
  }
  .bg2 {
    position: absolute;
    top: -150px;
    left: 0px;
    width: 100%;
    z-index: -1;
  }
  .bg3 {
    position: absolute;
    z-index: 99;
    width: 100%;
    left: 0px;
  }
  .bg4 {
    position: relative;
    width: 100%;
    top: 50px;
  }

  &-bg4container {
    position: absolute;
    z-index: 99;
    width: 100%;
    left: 0px;
    right: 0;
    bottom: 0px;
    overflow: hidden;
  }

  .bg,
  .bg2,
  .bg3,
  .bg4 {
    animation-name: Appearance;
    animation-duration: 2s;
    animation-timing-function: cubic-bezier(0.1, -0.6, 0.2, 0);
  }
}

@media screen and (max-width: 898px) {
  .home {
    &-main {
      &__info {
        h1 {
          font-size: 100px;
        }
      }
    }

    &-expertise {
      img {
        top: -9vw;
      }
    }

    &-technologies {
      flex-direction: column;
      margin-bottom: 0px;
      margin-top: 250px;
      height: max-content;
    }
  }
}

@media screen and (max-width: 768px) {
  .home {
    &-main {
      &__info {
        h1 {
          font-size: 90px !important;
          line-height: 72px;
        }
        h2 {
          font-size: 18px;
          line-height: 31px;
          margin-top: 20px;
        }
      }
      &__animate {
        &-logo {
          margin-bottom: 100px;
        }
      }
    }

    &-technologies {
      margin-top: 200px;
    }

    &-expertise {
      img {
        top: -6vw;
      }
    }

    &-approach {
      img {
        margin-bottom: 0;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .home {
    &-main {
      &__info {
        width: 100%;
        text-align: center;
        h1 {
          font-size: 65px !important;
          line-height: 72px;
        }
        h2 {
          margin-top: 30px;
          line-height: 1.5;
          font-size: 22px;
        }
      }
      &__animate {
        display: none;
      }
    }
    &-approach {
      h2 {
        font-weight: 200;
        font-size: 39px;
      }
      img {
        margin-top: 50px;
      }
    }

    &-technologies {
      margin-top: 150px;
    }

    &-expertise {
      img {
        top: 0vw;
      }
    }
  }
}
