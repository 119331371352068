.workModal {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(15px);
  background: rgba($color: #000000, $alpha: 0.7);
  z-index: 1100;
  overflow: hidden;

  &-container {
    position: relative;
    width: 100%;
    margin: auto;
    max-width: 1580px;
    height: 100%;
    border-radius: 12px;
    overflow: hidden;
    padding: 40px 40px 60px 40px;

    &-workscroll {
      position: relative;
      width: 100%;
      height: 100%;
      overflow-y: auto;
      top: 0px;
    }
  }

  &-close {
    position: absolute;
    width: 100%;
    height: 60px;
    border: none;
    background: transparent;
    color: #fff;
    z-index: 9999;
    bottom: 0px;

    backdrop-filter: blur(10px);
    border-top: 1px solid rgba(255, 255, 255, 0.3);

    &-cross {
      background: rgba($color: #ffffff, $alpha: 0.3);
      border-radius: 3px;
      padding: 2px;
      margin-right: 5px;
    }
  }
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 480px) {
  .workModal {
    &-container {
      height: 100%;
      border-radius: 12px;
      overflow: hidden;
      padding: 5px;
    }
  }
}
