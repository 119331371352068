@import './components/variables';

.workPreview {
  animation: appear 1s ease;
  &-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;
    padding: 20px;
    font-family: 'Poppins';
    border: 1px solid rgba(150, 150, 150, 0.1);
    border-radius: 8px 8px 0px 0px;
    border-bottom: none;

    gap: 20px;

    text-align: center;

    .title {
      color: $headings;
      font-size: 22px;
      font-weight: 300;
      margin: 0;
      padding: 0;
    }
    .date {
      color: #5f5f5f;
      font-weight: 300;
      font-size: 22px;
    }
  }
  &-wrap {
    display: flex;
    justify-content: space-between;
    &__content {
      animation: workAppear 1.5s ease;
      width: 80%;

      &-mobileOnly {
        display: none;
      }

      &-body {
        box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.5);

        img {
          width: 100%;
          height: 100%;
        }
      }

      &__title {
        font-family: 'Poppins';

        color: $headings;
        font-size: 24px;
        font-weight: 300;

        margin-bottom: 20px;

        display: none;
      }
    }
    &__nav {
      position: relative;
      .arrow {
        margin-top: 275px;
        &::before,
        &::after {
          content: '';
          position: absolute;
          top: 0px;
          left: calc(50% - 0.5px);
          width: 1px;
          height: 250px;
          background-color: #262224;
          cursor: auto;
        }
        &::after {
          top: 350px;
        }
      }

      .behance-link {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 70px;
        img {
          width: 42px;
          height: 42px;
        }
      }

      .close-btn {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }

      .arrowBtn {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 125px;
        .arrow-wrap {
          img {
            padding: 5px 1px;
          }
        }
      }
    }
    &__nav.left {
      .arrow {
        img {
          transform: rotate(180deg);
        }
      }
    }
    &__nav.right {
      .arrow::before {
        top: 205px;
        height: 50px;
      }
    }
  }
  &-nav {
    position: relative;
    z-index: 1000;
    margin: 0 auto;
    margin-top: 100px;
    padding-top: 50px;
    width: 80%;
    border-top: 1px solid #262224;
    &__wrap {
      display: flex;
      justify-content: space-between;
      margin-bottom: 125px;

      h3 {
        text-align: center;
        padding: 15px;
        font-weight: 100;
        color: $headings;
      }
      .col {
        width: 49%;
      }
      a {
        text-decoration-color: $primary;
      }
      &-work {
        border-radius: 8px;
        box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.5);

        img {
          width: 100%;
          height: 375px;
          border-radius: 8px 8px 0px 0px;
          object-fit: cover;
        }
        p {
          padding: 20px;
          color: $headings;
          font-size: 20px;
        }
      }
    }
  }

  .bg4 {
    position: absolute;
    z-index: 99;
    width: 100%;
    left: 0px;
    bottom: 0;
  }

  .bg,
  .bg2,
  .bg3,
  .bg4 {
    animation-name: Appearance;
    animation-duration: 2s;
    animation-timing-function: cubic-bezier(0.1, -0.6, 0.2, 0);
  }
}

@keyframes workAppear {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@media screen and (max-width: 1024px) {
  .workPreview {
    &-head {
      flex-direction: column;
    }
  }
}

@media screen and (max-width: 768px) {
  .workPreview {
    &-nav {
      &__wrap {
        flex-direction: column;
        .col {
          width: 100%;
        }
      }
    }
  }

  .workPreview-wrap__content__title {
    display: block;
  }
}

@media screen and (max-width: 768px) {
  .workPreview {
    padding: 20px;
    &-head {
      flex-direction: column;
      gap: 25px;
      width: 100%;
      margin: 40px 0px 0px 0px;
    }
    &-wrap {
      margin-top: 30px;
      margin-bottom: 30px;
      &__nav {
        position: absolute;
      }

      &__content {
        width: 95%;
        margin: 0 auto;
        &-mobileOnly {
          display: flex;
          margin-bottom: 20px;
          .dribble-link {
            width: 42px;
            height: 42px;
          }
          .behance-link {
            width: 42px;
            height: 42px;
            margin-right: 10px;
          }
          .arrow {
            margin-right: 30px;
          }
          .arrow.left {
            margin-left: auto;
            margin-right: 10px;
            img {
              transform: rotate(180deg);
            }
          }
        }
      }
      &__nav {
        display: none;
      }
    }
    &-nav {
      &__wrap {
        &-work {
          img {
            height: 175px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .workPreview {
    padding-top: 50px;
  }
}
