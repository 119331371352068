@import './components/variables';

.contactForm {
  position: relative;
  z-index: 1000;
  margin-bottom: 22vw;
  height: fit-content;
  &-header {
    display: flex;
    background: rgba(0, 0, 0, 0.4);
    padding: 30px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px 8px 0px 0px;
    border-bottom: 0;
    padding-bottom: 0;
    &__button {
      display: flex;
      align-items: center;
      margin-right: 75px;
      padding: 0px 10px;
      padding-bottom: 30px;
      border-bottom: 2px solid transparent;
      cursor: pointer;
      img {
        width: 64px;
        height: 64px;
        padding: 15px;
        border-radius: 8px;
        margin-right: 25px;
        border: 1px solid rgba(255, 255, 255, 0.1);
        filter: grayscale(100%);
        -webkit-filter: grayscale(100%);
        transition: border 0.1s ease;
      }
      &-span {
        text-transform: uppercase;
        font-size: 22px;
        font-weight: 500;
        color: $paragraph;
        transition: color 0.2s ease;
      }

      &:hover {
        img {
          filter: none;
          border: 1px solid $primary;
        }
        span {
          color: $primary;
        }
      }
    }
    &__button.active {
      border-bottom: 2px solid $primary;
      img {
        filter: none;
        border: 1px solid $primary;
      }
      span {
        color: $primary;
      }
    }
    hr {
      position: absolute;
      width: 100%;
      height: 2px;
      border: 0;
      left: 0px;
      background-color: rgba(255, 255, 255, 0.1);
      bottom: -16px;
      z-index: -20;
    }
  }
  &-content {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.4);
    border-top: 0;
    border-radius: 0px 0px 8px 8px;
    margin-bottom: 200px;
    box-shadow: (0px 25px 100px rgba(0, 0, 0, 0.6));
    &__wrap {
      display: flex;
      justify-content: space-between;
      padding: 60px 40px;
      &-col {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 49%;
        label {
          width: 48%;
          margin-bottom: 24px;
          position: relative;
          span {
            position: absolute;

            margin-left: 20px;
            transform: translateY(-50%);
            color: $primary;
            font-size: 14px;

            &::after {
              content: '';
              position: absolute;
              border-bottom: 3px solid #050505;
              
              width: 120%;
              margin-left: -110%;
              top: 50%;
              z-index: -1;
              
              transform: translateY(-35%);
            }
          }
          input {
            display: block;
          }
        }
        .textarea {
          width: 100%;
          textarea {
            resize: none;
            height: 100%;
          }
        }
      }
    }
    &__buttons {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      justify-content: center;
      gap: 30px;
      padding: 0px 40px;
      padding-bottom: 20px;
      &-btn {
        width: 73%;
        height: 72px;
      }
    }
    &__loader {
      top: 0px;
      width: 100%;
      height: 100%;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1001;
      background-color: rgba(0, 0, 0, 0.6);
    }
  }
}

@media screen and (max-width: 1200px) {
  .contactForm {
    margin-bottom: 0px;
    &-content {
      margin-bottom: 50px;
      box-shadow: none;
    }
    &-header {
      flex-direction: column;
      &__button {
        width: fit-content;
        margin-bottom: 25px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .contactForm {
    &-content {
      &__wrap {
        padding-bottom: 20px;
        flex-direction: column;
        &-col {
          width: 100%;
        }
      }
      &__buttons {
        flex-direction: column;
        gap: 0px;
        &-btn {
          width: 100%;
          margin-top: 25px;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .contactForm {
    &-content {
      margin-bottom: 50px;
      &__wrap {
        flex-direction: column;
        &-col {
          label {
            width: 100%;
            margin-bottom: 35px;
          }
        }
      }
    }
  }
}
