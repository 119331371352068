@import "./components/variables";

.works {
  margin: 75px 0px 0px;
  &-buttons {
    position: relative;
    display: flex;
    width: 100%;
    margin-bottom: 75px;
    &__button {
      display: flex;
      padding: 20px 30px;
      cursor: pointer;
      p {
        padding: 0;
        margin: 0;
        font-size: 22px;
        transition: 0.2s ease;
        color: $paragraph;
      }
      .img-0, .img-1, .img-2 {
        width: 36px;
        height: 36px;
        margin-right: 15px;
        transition: 0.2s ease;
      }

      .img-0 {
        background-image: url("../assets/icons/all-icon.svg");
        background-size: cover;
      }

      .img-1 {
        background-image: url("../assets/icons/group-icon.svg");
        background-size: cover;
      }

      .img-2 {
        background-image: url("../assets/icons/images-icon.svg");
        background-size: cover;
      }

      &:hover {
        .img-0 {
          background-image: url("../assets/icons/all-icon-red.svg");
        }
  
        .img-1 {
          background-image: url("../assets/icons/group-icon-red.svg");
        }

        .img-2 {
          background-image: url("../assets/icons/images-icon-red.svg");
        }
        p {
          color: $primary;
        }
      }
    }
    &__button.active {
      border-bottom: 2px solid $primary;
      p {
        color: $primary;
      }
      .img-0 {
        background-image: url("../assets/icons/all-icon-red.svg");
      }

      .img-1 {
        background-image: url("../assets/icons/group-icon-red.svg");
      }

      .img-2 {
        background-image: url("../assets/icons/images-icon-red.svg");
      }
    }
    hr {
      width: 100%;
      height: 2px;

      position: absolute;
      bottom: -16px;
      border: 0;
      background-color: rgba(255, 255, 255, 0.1);
    }
  }

  &-other {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 15px;
  }

  &-pagination {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 15px 80px ;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    margin: 0 auto 80px;
    &-counter {
      display: flex;
      align-items: center;
      gap: 10px;
      span{
        font-size: 15px;
        font-weight: 200;
        color: #FFFFFF;
      }
    }
    .arrow:nth-child(1) {
      img {
        transform: rotate(180deg);
      }
    }
  }
}
.videoMoreBtn {
  display: block;
  margin: 0 auto;
}

@media screen and (max-width: 480px) {
  .works {
    &-buttons {
      flex-direction: column;
    }
    &-pagination {
      &-counter {
        span {
          display: none;
        }
      }
    }
  }
}
