@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600&display=swap');

@import "./libs/normalize";
@import "../assets/fonts";
@import "./components/container";
@import "./components/variables";

body {
  font-family: 'Poppins', sans-serif;
  background-color: $bg;
}

::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: #333;
  border-radius: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #000000;
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.App {
  position: relative;
  overflow: hidden;
}
