.sceneBGHeader {
    position: absolute;
    width: 100%;
    height: 100vh;
    top: -20px;
    left: 0px;
    z-index: 49;
    overflow: hidden;

    &__scene2DSubHeader {
        position: relative;
        width: 500px;
        height: 100%;
    }

    &__scene3DHeader {
        width: 300px;
        height: 100vh;
        position: absolute;
        top: -20px;
        left: 0px;
        z-index: 50;
    }
}

.sceneBGExperience {
    margin-top: 100px;
    position: absolute;
    left: 0px;
    width: 100%;
    height: 700px;
    overflow: hidden;
    display: flex;
    justify-content: flex-end;

    &__scene2DSubExperience {
        position: relative;
        padding-top: 80px;
        width: 400px;
        height: 80%;
    }
}

.scene2DWorks {
    position: absolute;
    width: 100%;
    height: 750px;
    left: 0px;
    overflow: hidden;
}

canvas {
    width: 100%;
    height: 100%;
}

.sceneBGFooter {
    position: absolute;
    left: 0px;
    width: 100%;
    height: 100vh;
    overflow: hidden;

    &__scene3DFooter {
        position: relative;
        width: 100%;
        height: 100vh;
    }
}
