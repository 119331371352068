@import "./components/variables";

.videoPreview {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 10000;
  top: 0px;
  animation: appear 1s ease;
  
  .blackout {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
  }

  &-wrap {
    width: 90%;
    height: 90%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &__content {
      display: flex;
      justify-content: center;
      align-items: center;
      animation: workAppear 1.5s ease;
      width: 80%;

      &-body {
        width: 100%;
        height: 100%;
        &__video {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
    &__nav {
      position: relative;
      .arrow {
        margin-top: 40vh;
        &::before,
        &::after {
          content: "";
          position: absolute;
          top: 0px;
          left: calc(50% - 0.5px);
          width: 1px;
          height: 40%;
          background-color: #262224;
          cursor: auto;
        }
        &::after {
          top: 50vh;
        }
      }

      .close-btn {
        position: absolute;
        margin: 0;
      }

      .arrowBtn {
        position: absolute;
        top: 100px;
        .arrow-wrap {
          text-align: enter;
          img {
            padding: 5px 1px;
          }
        }
      }
    }
    &__nav.left {
      .arrow {
        img {
          transform: rotate(180deg);
        }
      }
    }
    &__nav.right {
      .arrow::before {
        top: 20vh;
        height: 15vh;
      }
      .dribble-link {
        position: absolute;
        left:0px;
        top: 10vh;
        left: 50%;
        width: 42px;
        height: 42px;
        transform: translateX(-50%);
        img {
          width: 100%;
          height: 100%;
          cursor: pointer;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .videoPreview {
    &-wrap {
      &__nav {
        display: none;
      }  
      &__content {
        width: 95%;
        margin: 0 auto;
        margin-top: 150px;
        display: block;
        &-mobileOnly {
          display: block;
          .dribble-link {
            width: 42px;
            height: 42px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .arrow {
            margin-right: 30px;
          }
          .arrow.left {
            margin-left: auto;
            margin-right: 10px;
            img {
              transform: rotate(180deg);
            }
          }
        }
        &-body {
          width: auto;
          height: auto;
        }
      }
    }
  }
}

@keyframes workAppear {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
