$primary: #ff383d;
$headings: #fff;
$paragraph: rgba(255, 255, 255, 0.3);
$bg: #101010;
$gradient: linear-gradient(135deg, #0d0d0d 0%, #1f1f1f 100%);

.primary-btn {
  position: relative;
  background-color: $primary;
  color: $headings;
  border: 0px;
  outline: none !important;
  padding: 16px 70px;
  font-size: 22px;
  font-weight: 400;
  z-index: 10;
  font-family: "Poppins";
  margin-bottom: 10px;
  transition: 0.1s ease;

  &::before {
    content: "";
    position: absolute;
    left: 0px;
    top: -3px;
    width: 100%;
    height: 3px;
    z-index: 5;
    border-radius: 6px 6px 0px 0px;
    transition: 0.1s ease;
    background-color: #ff6c70;
  }
  &::after {
    content: "";
    position: absolute;
    left: 0px;
    bottom: -3px;
    width: 100%;
    height: 3px;
    z-index: 5;
    background-color: #d80005;
    transition: 0.1s ease;
    border-radius: 0px 0px 6px 6px;
  }
  &:hover {
    background-color: #fc6f74;
    &::before {
      background-color: #ff9195;
    }
    &::after {
      background-color: #d14c4f;
    }
  }
  &:active {
    background-color: #d0272b;
    &::before {
      background-color: #ef494f;
    }
    &::after {
      background-color: #9a0004;
    }
  }
  &:disabled {
    background-color: #2e2e2e;
    span {
      background-color:rgba(255, 255, 255, 0.3);
    }
    &::before {
      background-color: #464646;
    }
    &::after {
      background-color: #191919;
    }
  }

  span {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 6px;
    width: 36px;
    height: 36px;
    background-color: lighten($primary, 10%);
    background-image: url("../../assets/icons/arrow-right.png");
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: center center;
  }
}

.primary-input {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  width: 100%;
  padding: 13px 20px;

  border: 1px solid $paragraph;
  
  border-radius: 12px;
  outline: none;
  background: rgba(0, 0, 0, 0.4);
  color: #5f5f5f;
  &::placeholder {
    color: #5f5f5f;
  }
}

.heading {
  text-align: center;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 60px;
  line-height: 90px;
  color: $headings;
  margin-bottom: 45px;
}

.arrowBtn {
  border-radius: 6px;
  padding: 7px;
  z-index: 0;
  background-color: $primary;
  transition: 0.2s ease;
  cursor: pointer;
  .arrow-wrap {
    padding: 0px 8px;
    background-color: $primary;
    border-radius: 6px;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.5);
    transition: 0.2s ease;
    .arrow {
      border: solid $headings;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 3px;
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
    }
  }
  &:hover {
    background-color: darken($primary, 2%);
    .arrow-wrap {
      background-color: lighten($primary, 2%);
    }
  }
}

.secondary-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  margin: 0;
  background-image: $gradient;
  border: 2px solid #262224;
  color: $headings;
  border-radius: 6px;
  transition: border 0.1s ease;
  cursor: pointer;
  &:hover {
    border-color: lighten(#262224,5%);
  }
}

.secondary-btn.active {
  background: $primary;
  border: 2px solid darken($primary, 1%);
  &:hover {
    border-color: lighten($primary,15%);
    background: lighten($primary, 5%);
  }
}

@keyframes Appearance {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

@media screen and (max-width: 480px) {
  .heading {
    font-weight: 200;
    font-size: 39px;
  }
}
