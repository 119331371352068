@font-face {
  font-family: "Museo Sans";
  font-weight: 100;
  src: local("Museo Sans"), url("./fonts/Museo\ Sans/MuseoSans-100.otf") format("opentype");
}

@font-face {
  font-family: "Museo Sans";
  font-weight: 300;
  src: local("Museo Sans"), url("./fonts/Museo\ Sans/MuseoSans-300.otf") format("opentype");
}

@font-face {
  font-family: "Museo Sans";
  font-weight: 500;
  font-style: italic;
  src: local("Museo Sans"), url("./fonts/Museo\ Sans/MuseoSans_500_Italic.otf") format("opentype");
}

@font-face {
  font-family: "Nexa";
  font-weight: 500;
  src: local("Nexa"), url("./fonts/Nexa/NexaRegular.otf") format("opentype");
}

@font-face {
  font-family: "Nexa";
  font-weight: 100;
  src: local("Nexa"), url("./fonts/Nexa/NexaThin.otf") format("opentype");
}

@font-face {
  font-family: "Nexa";
  font-weight: 900;
  src: local("Nexa"), url("./fonts/Nexa/NexaBlack.otf") format("opentype");
}

@font-face {
  font-family: "Nexa Heavy";
  font-weight: 400;
  src: local("NexaHeavy"), url("./fonts/Nexa/NexaHeavy.otf") format("opentype");
}
