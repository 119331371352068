@import "./components/variables";

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 90px;
  position: relative;
  z-index: 1000;

  &-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 40%;
    margin: 0;
    list-style: none;

    .link {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 300;
      font-size: 22px;
      line-height: 36px;
      text-decoration: none;
      color: #5f5f5f;

      transition: 0.2s ease-in-out;
      cursor: pointer;
      &:hover {
        color: $primary;
        border-color: $primary;
      }
    }

    .link.active {
      color: $primary;
      border-color: $primary;
    }
  }

  &-hidden-menu {
    display: none;
    color: rgba(255,255,255,0.5);
    font-size: 24px;
    text-decoration: underline;
  }
}

@media screen and (max-width: 1024px) {
  .header {
    &-menu {
      width: 60%;
    }
  }
}

@media screen and (max-width: 768px) {
  .header {
    height: fit-content;
    width:100%;
    left: 0;
    align-items: flex-start;
    position: relative;
    z-index: 20000;

    backdrop-filter: blur(10px);
  border-bottom: 1px solid rgba(255,255,255,0.3);
    &-menu {
      display: none;
      width: 100%;
      padding-top: 30%;
      padding-right: 30px;
      
        .link {
          width: fit-content;
          margin-left: auto;
          color: rgba(255, 255, 255, 0.3);
          border-bottom-color: $headings;
        }
      
    }

    &-logo {
      position: relative;
      z-index: 20005;
      padding: 20px;
    }

    &-menu.active {
      display: block;
      position: fixed;
      right: 0px;
      width: 100%;
      height: 100%;
      background-image: url("../assets/MenuBG.jpg");
      background-size: 110%;
      .link {
        font-size: 36px;
      }
    }

    &-hidden-menu {
      display: block;
      position: relative;
      z-index: 20000;
      padding-top: 20px;
      margin-right: 20px;
      text-decoration: none;
      border-bottom: 1px solid rgba(255,255,255,0.5);
      span {
        display: none;
        font-size: 16px;
      }
    }
    &-hidden-menu.active {
      color: #FF383D;
      border-color: $primary;
      span {
        display: inline;
      }
    }

    &-hidden__footer {
      &__col {
        width: 35%;
        border-right: 1px solid rgba(255,255,255,0.3);
        &-p {
          color: $headings;
        }
        a {
          margin-right: 10px;
          img {
            width: 38px;
            height: 38px;
          }
        }
        
        #email {
          width: 43px;
          height: 43px;
          margin-top: 5px;
        }
      }
      &__col:nth-child(2) {
        width: 60%;
        border:none;
        a {
          img {
            margin-top: 5px;
          }
        }
      }
    }
    &-hidden__footer.active {
      width: 100%;
      padding: 20px;
      padding-bottom:10vh;
      position: absolute;
      justify-content: space-between;
      background-color: rgba(0, 0, 0, 0.3);
      border-top: 1px solid rgba(255,255,255,0.3);
      bottom: 0;
      left: 0px;
    }
  }

  .header.active {
    position: fixed;
    height: 100vh;
  }
}

@media screen and (max-width: 768px) {
  .header {
    position: fixed;
    &-menu {
      flex-direction: column;
      .link {
        display: block;
        margin-bottom: 30px;
      }
    }
  }
}
