@import "./components/variables";

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 30%;
  height: 572px;
  top: 40px;
  border: 2px solid rgba(255,255,255,0.1);
  border-radius: 12px;
  background-position: -5px;
  color: rgba(255,255,255,0.3);
  position: relative;
  cursor: pointer;
  transition: 0.3s ease;

  .item-btn {
    background-color: #2e2e2e;
    margin-top: 45px;
    margin-bottom: 75px;
    span {
      width: 32px;
      height: 32px;
      background-color:rgba(255, 255, 255, 0.3);
    }
    &::before {
      background-color: #464646;
    }
    &::after {
      background-color: #191919;
    }
  }
  &-wrap {
    padding: 20px;
    img {
      display: block;
      margin: 0 auto;
      margin-top: -50px;
      filter: grayscale(100%);
      -webkit-filter: grayscale(100%);
      transition: 0.2s ease;
      width: 125px;
      height: 125px;
    }

    h3 {
      font-family: "Poppins";
      text-transform: uppercase;
      font-weight: 900;
      font-size: 28px;
      margin-top: 50px;
      transition: 0.2s ease;
      margin-bottom: 25px;
    }

    ul {
      list-style: none;
      padding: 0;
      li {
        font-family: "Poppins";
        font-weight: 300;
        font-size: 26px;
        transition: 0.2s ease;
        margin-top: 10px;
      }
      li:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  &:hover {
    transform: scale(1.15);
    top: 0px;
    width: 38%;
    height: 650px;
    border-radius: 12px;
    box-shadow: (0px 25px 100px rgba(0, 0, 0, 0.6));
    z-index: 1005;
    .BGOne {
      width: 100%;
      height: 100%;
      border-radius: 12px;
      background-image: url("../assets/pref-bg-one.jpg");
      background-size: 150%;
      background-position-x: -40px;
      background-repeat: no-repeat;
    }
    .BGTwo {
      width: 100%;
      height: 100%;
      border-radius: 12px;
      background-image: url("../assets/pref-bg-two.jpg");
      background-size: 150%;
      background-position-x: -40px;
      background-repeat: no-repeat;
    }
    .BGThree {
      width: 100%;
      height: 100%;
      border-radius: 12px;
      background-image: url("../assets/pref-bg-three.jpg");
      background-size: 150%;
      background-position-x: -40px;
      background-repeat: no-repeat;
    }
    img {
      margin-top: -120px;
      width: 175px;
      height: 175px;
      filter: none;
      -webkit-filter: grayscale(0);
    }
    h3,
    li {
      color: $headings;
    }
    .item-btn {
      background-color: $primary;
      &::after {
        background-color: #d80005;
      }
      &::before {
        background-color: #ff6c70;
      }
      &:active {
        background-color: #d0272b;
        &::before {
          background-color: #ef494f;
        }
        &::after {
          background-color: #9a0004;
        }
      }
    }
  }
}


@media screen and (max-width: 898px) {
  .item {
    width: 80%;
    margin: 0 auto;
    margin-bottom: 150px;

    &:hover {
      top: 40px;
      transform: scale(1);
      width: 90%;
      .BGOne {
        background-position-x: -70px;
        background-size: 180%;
      }
      .BGTwo {
        background-position-x: -70px;
        background-size: 180%;
      }
      .BGThree {
        background-position-x: -70px;
        background-size: 180%;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .item {
    .item-btn {
      white-space: nowrap;
      font-size: 18px;
    }
  }
}