@import "./components/variables";

.workVideoPreview {
  width: 31%;
  position: relative;
  cursor: pointer;
  margin-bottom: 24px;
  animation: appear 2s;
  .media {
    border-radius: 12px;
    transition: 0.1s ease-in;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  &__img {
    width: 100px;
    height: 100px;
    position: absolute;
    top: calc(50% - 50px);
    left: calc(50% - 50px);
    opacity: 0;
    transition: 0.3s ease;
  }
  &:hover {
    .media {
      filter: blur(4px);
    }
    img {
      opacity: 1;
    }
  }
}

@keyframes appear {
  0% {
    opacity: 0;
    transform: scale(0.95);
  }
  20% {
    transform: scale(1);
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 768px) {
  .workVideoPreview {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 20px;
  }
}
