.approach {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 2%;
  position: relative;
  margin: 200px 0;

  &__label {
    position: absolute;
    color: white;

    width: 25%;

    top: -5%;

    display: flex;
    justify-content: center;

    padding-top: 1.5%;

    &__title {
      font-size: 1vw;
    }

    &__arrow {
      position: absolute;
      bottom: 0;
      height: 15px;

      transform: translate(49.8%, 50%);

      &.bottom {
        top: 0;
  
        transform: translate(-49.5%, -59%) rotate(180deg);
      }
    }

    &.left {
      left: 0%;
      height: 30%;

      /*dotts*/
      background-image: linear-gradient(rgba(255, 255, 255, 0) 33%, rgba(255, 255, 255, 0.1) 0%),
        linear-gradient(to right, rgba(255, 255, 255, 0) 33%, rgba(255, 255, 255, 0.1) 0%),
        linear-gradient(rgba(255, 255, 255, 0) 33%, rgba(255, 255, 255, 0.1) 0%);
      background-position: left, top, right;
      background-size: 2px 10px, 10px 2px, 2px 10px;
      background-repeat: repeat-y, repeat-x, repeat-y;

      transform: translate(50%, -100%);
    }
    &.center {
      right: 50%;
      height: 22%;

      /*dotts*/
      background-image: linear-gradient(
          to right,
          rgba(255, 255, 255, 0) 33%,
          rgba(255, 255, 255, 0.1) 0%
        ),
        linear-gradient(rgba(255, 255, 255, 0) 33%, rgba(255, 255, 255, 0.1) 0%);
      background-position: top, right;
      background-size: 10px 2px, 2px 10px;
      background-repeat: repeat-x, repeat-y;

      transform: translate(50%, -100%);
    }
    &.right {
      right: 0;
      height: 14%;

      /*dotts*/
      background-image: linear-gradient(
          to right,
          rgba(255, 255, 255, 0) 33%,
          rgba(255, 255, 255, 0.1) 0%
        ),
        linear-gradient(rgba(255, 255, 255, 0) 33%, rgba(255, 255, 255, 0.1) 0%);
      background-position: top, right;
      background-size: 10px 2px, 2px 10px;
      background-repeat: repeat-x, repeat-y;

      transform: translate(-50%, -100%);
    }
  }

  &__labelBottom {
    position: absolute;
    color: white;

    bottom: -5%;

    width: 25%;

    display: flex;
    justify-content: center;

    padding-bottom: 1.5%;

    &.left {
      left: 0%;
      height: 14%;

      /*dotts*/
      background-image: linear-gradient(rgba(255, 255, 255, 0) 33%, rgba(255, 255, 255, 0.1) 0%),
        linear-gradient(to right, rgba(255, 255, 255, 0) 33%, rgba(255, 255, 255, 0.1) 0%),
        linear-gradient(rgba(255, 255, 255, 0) 33%, rgba(255, 255, 255, 0.1) 0%);
      background-position: left, bottom, right;
      background-size: 2px 10px, 10px 2px, 2px 10px;
      background-repeat: repeat-y, repeat-x, repeat-y;

      transform: translate(50%, 100%);
    }
    &.center {
      right: 50%;
      height: 28%;
      width: 50%;

      /*dotts*/
      background-image: linear-gradient(rgba(255, 255, 255, 0) 33%, rgba(255, 255, 255, 0.1) 0%),
        linear-gradient(to right, rgba(255, 255, 255, 0) 33%, rgba(255, 255, 255, 0.1) 0%),
        linear-gradient(rgba(255, 255, 255, 0) 33%, rgba(255, 255, 255, 0.1) 0%);
      background-position: left, bottom, right;
      background-size: 2px 10px, 10px 2px, 2px 10px;
      background-repeat: repeat-y, repeat-x, repeat-y;

      transform: translate(25%, 100%);

      display: flex;
      align-items: end;
      justify-content: end;

      padding-right: 1.5%;
    }
    &.right {
      right: 0;
      height: 42%;
      width: 75%;

      /*dotts*/
      background-image: linear-gradient(rgba(255, 255, 255, 0) 33%, rgba(255, 255, 255, 0.1) 0%),
        linear-gradient(to right, rgba(255, 255, 255, 0) 33%, rgba(255, 255, 255, 0.1) 0%),
        linear-gradient(rgba(255, 255, 255, 0) 33%, rgba(255, 255, 255, 0.1) 0%);
      background-position: left, bottom, right;
      background-size: 2px 10px, 10px 2px, 2px 10px;
      background-repeat: repeat-y, repeat-x, repeat-y;

      transform: translate(-16.65%, 100%);

      display: flex;
      align-items: end;
      justify-content: end;

      padding-right: 1.5%;
    }
  }

  &__item {
    position: relative;

    &__label {
      position: absolute;
      top: 50%;
      right: 50%;
      font-size: 1.9vw;

      transform: translate(50%, -50%);

      color: white;
      text-align: center;
    }
  }

  // -------------------------------------------------animations-------------------------------------------------

  @-webkit-keyframes rotate-center {
    0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes rotate-center {
    0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}

@-webkit-keyframes scale-up-center {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes scale-up-center {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

// --------------------------------------------------------------------------------------------------

[data-inviewport='circle1'].is-inViewport {
  -webkit-animation: rotate-center 2s cubic-bezier(0.19, 1, 0.22, 1) 0s both;
  animation: rotate-center 2s cubic-bezier(0.19, 1, 0.22, 1) 0s both;
}
[data-inviewport='circle2'].is-inViewport {
  -webkit-animation: rotate-center 2s cubic-bezier(0.95, 0.05, 0.795, 0.035) 2s reverse both;
  animation: rotate-center 2s cubic-bezier(0.95, 0.05, 0.795, 0.035) 2s reverse both;
}
[data-inviewport='circle3'].is-inViewport {
  -webkit-animation: rotate-center 2s cubic-bezier(0.19, 1, 0.22, 1) 4s both;
  animation: rotate-center 2s cubic-bezier(0.19, 1, 0.22, 1) 4s both;
}
[data-inviewport='circle4'].is-inViewport {
  -webkit-animation: rotate-center 2s cubic-bezier(0.95, 0.05, 0.795, 0.035) 6s reverse both;
  animation: rotate-center 2s cubic-bezier(0.95, 0.05, 0.795, 0.035) 6s reverse both;
}

[data-inviewport='labelTopLeft'].is-inViewport {
  -webkit-animation: scale-up-center 1s 2s both;
  animation: scale-up-center 1s 2s both;
}
[data-inviewport='labelTopCenter'].is-inViewport {
  -webkit-animation: scale-up-center 1s 4s both;
  animation: scale-up-center 1s 4s both;
}
[data-inviewport='labelTopRight'].is-inViewport {
  -webkit-animation: scale-up-center 1s 6s both;
  animation: scale-up-center 1s 6s both;
}

// -------------------------------------------------media-------------------------------------------------

@media screen and (min-width: 1260px) {
  .approach__item__label {
    font-size: 25px;
  }

  .approach__label__title {
    font-size: 18px;
  }
}

@media screen and (max-width: 898px) {
  .home {
    &-main {
      &__info {
        h1 {
          font-size: 100px;
        }
      }
    }

    &-expertise {
      img {
        top: -9vw;
      }
    }

    &-technologies {
      flex-direction: column;
      margin-bottom: 0px;
      margin-top: 250px;
      height: max-content;
    }
  }
}

@media screen and (max-width: 768px) {
  .approach__label__arrow {
    height: 8px;
  }
}

@media screen and (max-width: 480px) {
  .approach__label__arrow {
    height: 8px;
  }
}
