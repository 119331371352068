.experiencedBox {
  &__shapesImg {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
  }

  &__label {
    position: absolute;
    font-size: 2vw;

    color: #fff;
  }
}

@media screen and (min-width: 1260px) {
  .experiencedBox__label {
    font-size: 25px;
  }
}
