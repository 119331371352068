@import "./components/variables";

.projectDetails {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0px;
  z-index: 1000;
  animation: appear 0.2s;

  .blackout {
    width: 100%;
    height: 100vh;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1001;
    backdrop-filter: blur(5px);
    animation: appear;
  }

  &-wrap {
    position: relative;
    width: 80%;
    margin: 0px auto;
    margin-top: 50px;
    background-image: linear-gradient(117deg, #0d0d0d 0%, #1f1f1f 100%);
    border: 1px solid #333333;
    z-index: 2000;

    &__head {
      display: flex;
      align-items: center;
      padding: 40px;
      border-bottom: 1px solid #333333;
      .close-btn {
        margin-left: auto;
      }
      &-title {
        font-size: 24px;
        font-weight: 400;
        color: $primary;
        margin-left: 25px;
      }
    }
    &__content {
      display: flex;
      padding: 40px 0px;
      margin-bottom: 40px;
      border: 1px solid rgba(150, 150, 150, 0.1);
      border-radius: 0px 0px 8px 8px;
      &-section{
        display: flex;
        flex-direction: column;
      }
      &-sidebody {
        width: 100%;
        overflow: auto;
        height: 80vh;
      }
      &-sidebar {
        width: 23%;
        overflow: auto;
        height: 375px;
        padding-left: 36px;
        &__p {
          display: block;
          font-size: 20px;
          color: rgba(255, 255, 255, 0.3);
          margin-bottom: 15px;
          position: relative;
          text-decoration: none !important;
          &:hover {
            color: $primary;
            &::before {
              border-color: $primary;
            }
          }
          cursor: pointer;
          &::before {
            content: "";
            display: inline-block;
            position: absolute;
            top: 10px;
            left: -15px;
            border: solid rgba(255, 255, 255, 0.3);
            border-width: 0 2px 2px 0;
            padding: 4px;
            transform: rotate(135deg);
            -webkit-transform: rotate(135deg);
            transition: 0.1s ease;
          }
        }
        &__p.active {
          color: $primary;
          &::before {
            content: "";
            border-color: $primary;
            transform: rotate(-45deg);
            left: -20px;
            -webkit-transform: rotate(-45deg);
          }
        }
      }
      &-main {
        width: 77%;
        border-left: 1px solid #333333;
        padding: 0px 30px;
        margin-right: 20px;
        height: 375px;
        overflow: auto;
        &__p {
          font-size: 22px;
          font-weight: 300;
          color: $headings;
          span {
            display: block;
            color: $primary;
            margin-bottom: 15px;
          }
        }
      }
    }
  }
}


@media screen and (max-width: 1024px) {
  .projectDetails {
    &-wrap {
      width: 95%;
      &__content {
        &-sidebar {
          width: fit-content;
          padding: 0px 50px;
        }
        &-main {
          width: fit-content;
        }
      }
    }
  }
}

@media screen and (max-width: 586px) {
  .projectDetails {
    &-wrap {
     
      &__content {
        padding-left: 6px;
        &-sidebody {
          padding-left: 0px;
        }
        &-sidebar {
          display: none;
        }
        &-main {
          width: 100%;
          border: 0;
          padding: 0px ;
          margin-right: 0px;
        }
      }
    }
  }
}
