@import "./components/variables";

.footer {
  position: relative;
  z-index: 1000;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255,255,255,0.3);
  border-left: 0px;
  border-right: 0px;
  // margin-top: 500px;

  &-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(255,255,255,0.3);
    padding: 30px;
    .wrap {
      display: flex;
      ul {
        display: flex;
        align-items: center;
        list-style: none;
        padding: 0 15px 0 22px;
        margin: 0;
        border-left: 1px solid rgba(255, 255, 255, 0.1);
        li {
          margin-right: 15px;
          font-size: 18px;
          a {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 27px;
            color: $headings;
            text-decoration: none;
            transition: 0.2s ease;
            &:hover {
              color: $primary;
            }
          }
        }
        li:nth-child(3) {
          margin-right: 0;
        }
      }
      .contacts-wrap {
        display: flex;
      }
      .contacts {
        display: flex;
        align-items: center;
        border-left: 1px solid rgba(255, 255, 255, 0.1);
        margin-right: 15px;
        span {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 27px;
          color: $headings;
          margin: 0px 18px;
        }
      }
      a {
        transition: 0.2s ease;
        margin-right: 5px;
        img {
          width: 37px;
          height: 37px;
        }
        &:hover {
          transform: scale(1.1);
        }
      }
      #email {
        width: 40px;
        height: 40px;
        margin-top: 5px;
      }
    }
  }
  &-copyright {
    padding: 20px 0px;
    text-align: center;
    color: rgba(255,255,255,0.5);
  }
}

@media screen and (max-width: 1024px) {
  .footer {
    &-head {
      flex-direction: column;
      .logo {
        margin-bottom: 25px;
      }
      .arrowBtn {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .footer {
    &-head {
      .wrap {
        flex-direction: column;
        align-items: center;
        .contacts {
          span {
            display: none;
          }
          a {
            img {
              width: 42px;
              height: 42px;
              margin-left: 10px;
            }
          }
          #email {
            width: 47px;
            height: 47px;
          }
        }
      }
      &__list {
        margin: 20px 0px 30px !important;
      }
    }
  }
}
