@import "./components/variables";

.workDetailed {
  display: flex;
  height: 520px;
  overflow: hidden;
  background-color: #000000;
  box-shadow: 0px 20px 30px 0px #000000;
  border-radius: 12px;
  animation: appear 2s;
  margin: 0 15px 55px;

  &__img {
    width: 50%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 12px 0px 0px 12px;
    }
  }
  &__info {
    width: 50%;
    height: 100%;
    background-color: $bg;
    border-radius: 0px 12px 12px 0px;
    position: relative;
    &-wrap {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      padding: 40px 30px;
      h2 {
        color: $headings;
        font-weight: 600;
        font-size: 28px;
        line-height: 40px;
        margin-bottom: 10px;
        padding-bottom: 20px;
        border-bottom: 1px solid rgba(#ffffff, 0.3);
      }
      p {
        flex: 1;
        color: #7f7f7f;
        font-size: 24px;
        line-height: 40px;
        padding: 0;
        margin: 0;
      }
      span {
        color: $headings;
      }
      ul {
        display: flex;
        padding: 0;
        width: 100%;
        margin: 15px 0px;
        list-style: none;
        li {
          color: $primary;
          font-size: 18x;
          font-weight: 400;
          margin-right: 10px;
          text-transform: uppercase;
          text-decoration: underline;
        }
      }
      
      button {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        height: 62px;
        span {
          width: 32px;
          height: 32px;
          right: 10px;
        }
      }
    }
  }
}

.workDetailed.revert {
  flex-direction: row-reverse;
  .workDetailed__img {
    img {
      border-radius: 0px 12px 12px 0px;
    }
  }
  .workDetailed__info {
    border-radius: 12px 0px 0px 12px;
  }
}

@keyframes appear {
  0% {
    opacity: 0;
    transform: scale(0.95);
  }
  20% {
    transform: scale(1);
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 1100px) {
  .workDetailed {
    &__info {
      border-radius: 0px 0px 12px 12px;
      &-wrap {
        h2 {
          font-weight: 500;
          font-size: 20px;
          line-height: 40px;
        }
        p {
          font-size: 18px;
          line-height: 30px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .workDetailed, .workDetailed.revert  {
    flex-direction: column;
    border-radius: 12px;
    height: 100%;
    &__img, &__info {
      width: 100%;
    }
    &__img {
      img {
        border-radius: 12px 12px 0px 0px !important;
      }
    }
    &__info {
      border-radius: 0px 0px 12px 12px;
      &-wrap {
        height: 500px;
      }
    }
  }
}


@media screen and (max-width: 480px) {
  .workDetailed {
    &__info {
      &-wrap {
        padding: 20px;
        padding-bottom: 60px;
        ul {
          li {
            font-size: 15x;
            font-weight: 300;
          }
        }
      }
    }
  }
}
